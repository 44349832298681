.boxitem{
  background-color: #fff;
  background-image: url('../../assets/imgbox.png');
  background-repeat: no-repeat;
  background-size: 100%;
  border-radius: 20px;
  box-shadow: 5px 5px 25px rgba(0,0,0,.08);
  overflow: hidden;
  padding: 40px;
  margin: 15px 0 15px 15px;
  position: relative;
  z-index: 2;
}
.boxitem:hover span svg{
  animation: shake 0.2s ease-in-out 2;
}
.boxitem span{
  background: #fff;
  border: 2px solid rgba(86,90,207,.1);
  border-radius: 13px;
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.boxitem span svg{ 
  width: 55px;
}
.boxitem h3{
  color: #ffbe12;
  font-weight: 700;
  margin: 15px 0;
}
.boxitem .btn{
  background-color: #e1e2f6;
  border: 1px solid #e1e2f6;
  color: #5c5c5d;
  padding: 6px 18px;
  outline: none;
}
.boxitem .btn:hover{
  background-color: #cfcfdd;
  color: #5d5d5d;
}



@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
}
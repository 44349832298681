.infoitem{
  background-color: #ffbe12;
  border-radius: 40px 0 40px 40px;
  box-shadow: 0 0 30px rgba(185,67,0,.1);
  margin: 50px 20px 20px;
  padding: 40px;
  position: relative;

}
.infoitem p{
  color: #fff;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 30px;
}
.infoitem h3{
  color: #fff;
  font-size: 27px;
  font-weight: 600;
  margin-bottom: 11px;
}
.infoitem h4{
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  text-transform: uppercase;
}
.infoitem .faQuoteLeft{
    color: #e9e9e9;
    font-size: 92px;
    line-height: 92px;
    position: absolute;
    left: 12px;
    top: -36px;
    display: none !important;
}
.infoitem .faQuoteRight{
  bottom: 20px;
  color: #fff;
  font-size: 92px;
  line-height: 92px;
  opacity: .3;
  position: absolute;
  right: 40px;
  display: none !important;
}
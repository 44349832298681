/* global.css or index.css */
@font-face {
  font-family: 'Poppins';
  src: url('../src/assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype'),
       url('../src/assets/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}


body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#f8f9fa !important
}

code {
  font-family: 'Poppins' !important;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show{
color: rgb(31, 31, 31) !important;
}
.dropdown-item{
  color: rgb(31, 31, 31) !important;
}
.nav-link {
  color: rgb(31, 31, 31) !important;
}
.bg-remove{
 
    background-image: none;

}
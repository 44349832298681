*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

:root {
  --primary-fw: 700;
  --primary-fs: 15px;
  --primary-linear-gradient: hsl(38, 99%, 49%), hsl(236, 94%, 7%);
  --neutral-very-light-grayish-blue: hsl(240, 78%, 98%);
  --neutral-light-grayish-blue: hsl(234, 14%, 74%);
  --neutral-grayish-blue: hsl(42, 95%, 44%);
  --neutral-dark-grayish-blue: hsl(232, 13%, 33%);
  --neutral-white: hsl(0, 0%, 100%);
}


.PricingApp {
  position: relative;
  min-height: 100vh;
  min-height: 100dvh;
  font-family: "Montserrat", sans-serif;
  font-weight: var(--primary-fw);
  font-size: var(--primary-fs);
  display: grid;
  place-items: center;
  background-color: var(--neutral-very-light-grayish-blue);
  background-image: url("../../assets/bg-top.svg");
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 375px 658px;
  z-index: 999;
}

.PricingApp::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 353px;
  width: 100%;
  height: 304px;
  background-image: url("../../assets/bg-bottom.svg");
  background-repeat: no-repeat;
  background-size: contain;
  z-index: -1;
}

button {
  font-family: "sans", sans-serif;
  font-weight: var(--primary-fw);
  font-size: var(--primary-fs);
}

/*toggle*/
.PricingApp .price-switch {
  --border-radius: 10em;
  display: block;
  width: 50px;
  height: 25px;
  border-radius: var(--border-radius);
  position: relative;
  cursor: pointer;
}

.PricingApp .price-checkbox {
  opacity: 0;
  height: 0;
  width: 0;
}

.PricingApp .switch-slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 25px;
  border-radius: var(--border-radius);
  background-image: linear-gradient(to right, var(--primary-linear-gradient));
}

.PricingApp .switch-slider:is(:hover, :focus-visible) {
  opacity: 0.7;
}

.PricingApp .switch-slider::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 4px;
  width: 20px;
  border-radius: 50%;
  aspect-ratio: 1;
  background-color: var(--neutral-very-light-grayish-blue);
  transform: translateX(calc(100% + 2px));
  transition: 0.3s ease;
}

.PricingApp .price-checkbox:checked + .switch-slider::before {
  transform: translateX(0%);
}
/*------*/

.PricingApp .app-container {
  width: min(90%, 70rem);
}

.PricingApp header .header-topic {
  text-align: center;
  color: var(--neutral-dark-grayish-blue);
}

.PricingApp header > .header-row {
  margin-top: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
}

.PricingApp .pricing-cards {
  margin-top: 4em;
  min-height: 30rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 800px) {
  .PricingApp {
    padding-block: 4em;
  }
  .PricingApp .pricing-cards {
    flex-direction: column;
    gap: 2em;
  }
}
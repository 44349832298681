.PricingCard {
  padding: 2em;
  border-radius: 1em;
  text-align: center;
  color: var(--neutral-dark-grayish-blue);
  background-color: var(--neutral-white);
  width: min(100%, 30rem);
}

.PricingCard:nth-child(2) {
  background-image: linear-gradient(to right, var(--primary-linear-gradient));
  color: var(--neutral-white);
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}
.PricingCard:nth-child(2) h2 {
color: azure !important;
  }
  .PricingCard:nth-child(3) h2 {
    color: rgba(0, 34, 34, 0.678) !important;
      }
      .PricingCard:nth-child(4) h2 {
        color: rgba(0, 34, 34, 0.678) !important;
          }
      .PricingCard:nth-child(1) h2 {
        color: rgba(0, 24, 24, 0.723) !important;
          }
              
.PricingCard header > .card-price {
  margin-block: 1rem;
  font-size: 3em;
}


.PricingCard .card-features > * {
  padding-block: 1em;
  border-bottom: 1px solid var(--neutral-light-grayish-blue);
}

.PricingCard .card-features :first-child {
  border-top: 1px solid var(--neutral-light-grayish-blue);
}

.PricingCard .card-features :last-child {
  margin-bottom: 2em;
}
.text-gold{
    color: rgb(255, 166, 0) !important;

}
.PricingCard .card-btn {
  cursor: pointer;
  padding-block: .6em;
  width: 100%;
  border-radius: 0.5em;
  background-image: linear-gradient(to right, var(--primary-linear-gradient));
  border: 0px solid transparent;
  outline: transparent;
  transition: 0.125s ease;
  color: var(--neutral-white);
}

.PricingCard .card-btn:is(:focus-visible, :hover) {
  color: hsl(36, 88%, 43%);
  background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, var(--primary-linear-gradient)) border-box;
  border-radius: 0.5em;

}

.PricingCard:nth-child(2) .card-btn {
  background: transparent;
  background-color: var(--neutral-white);
  color: hsl(36, 88%, 43%);
}

.PricingCard:nth-child(2) .card-btn:is(:focus-visible, :hover) {
  color: var(--neutral-white);
  background: linear-gradient(to right, var(--primary-linear-gradient))
      padding-box,
    linear-gradient(white, white) border-box;
  border-radius: 0.5em;
  border: 2px solid transparent;
}

@media screen and (max-width: 800px) {
  .PricingCard:nth-child(2) {
    align-self: center;
  }
}

h2{
    color:#ffbe12 !important;
    font-family: 'Tajawal', sans-serif !important;
    padding: 5px;

    font-weight: 600 !important;


}
a{
    
        color:rgb(255, 255, 255)!important;

        padding: 7px;
    
       
}
h4{
    color:rgb(255, 255, 255)!important;

}
.category-item {
    width: 90% !important; /* Adjust the margin value as per your preference */
  }
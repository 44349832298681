.titlebasic{
background: #ae4f0657;
border-radius: 15px;
  background-position: 50%;
  background-repeat: repeat;
  display: inline-block;
  font-weight: 700;
  margin-bottom: 15px;
  padding: 3px 15px;
  color:rgb(255, 255, 255) !important;
  font-size: 20px;
}
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400&family=Poppins:wght@400;500;600;900&family=Roboto&family=Rubik&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400&family=Poppins:wght@400;900&family=Roboto:wght@400;700&family=Rubik&display=swap');

body{
  overflow-x: hidden !important;
 
}
.sec1-forex{
  background-image: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.8));
  /* Change 'to right' to other directions like 'to left', 'to top', or 'to bottom' */
    
   background-repeat: no-repeat;
    background-size: cover;
    height: 100vh !important;





}
.sec1-texts{
    
    z-index: 5;
    
  
    
}

.sec1-forex h1{
color: rgb(223, 209, 10)important;
text-align: center !important;

font-weight:700;

}
.sec1-forex p{
    color: rgb(191, 189, 189) !important;
    text-align: center !important;

    font-weight:500;
    font-size: large;
    padding-top:20px;
    line-height: 30px;
    width:100%;
    text-align: center
    }
.sec2-forex{
        background-color: rgb(246, 246, 247) !important;
}
.sec2-forex h1{
    background-color: rgb(246, 246, 247) !important;

    font-weight: 600;
}

.sec2-forex p{
    background-color: rgb(246, 246, 247) !important;
font-size: large;
width:100% !important;
    
}
.sec3-forex-container{
    background-color: rgb(248, 248, 248);
    text-align: center;
}


@media screen and (max-width: 600px) {
  .moving-image {
    width: 100%;
  }
}

@media screen and (min-width: 601px) {
  .moving-image {
    width: 75%;
  }
}
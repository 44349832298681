
/* CSS */
.button-63 {
    align-items: center;
    background-image: linear-gradient(144deg,#000000, #925106 50%,#f98705);
    border: 0;
    border-radius: 8px;
    box-shadow: #05030033 0 15px 30px -5px;
    box-sizing: border-box;
    color: #FFFFFF;
    display: flex;
    font-family: Phantomsans, sans-serif;
    font-size: 20px;
    justify-content: center;
    line-height: 1em;
    max-width: 100%;
    min-width: 140px;
    padding: 19px 24px;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    cursor: pointer;
  }
  
  .button-63:active,
  .button-63:hover {
    outline: 0;
  }
  .img-illustration{
width: 50%;
  }
  @media (min-width: 768px) {
    .button-63 {
      font-size: 24px;
      min-width: 196px;
    }
  }


  .text-primary{
    color: #ffbf00 !important;
  }
  .head-img{
    min-height: 240px;
  }


.header{
  position: relative;
height: 100vh !important;
  background-color: #f7f7ff;
  overflow: hidden;
}

.background-container {
  position: relative;
  width: 100%;

  height: 100%;
  height:550px;
}

.background-image {
  position: absolute;
  top: 0;

  left: 0;
  height: 100vh;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  filter: brightness(0.6); 
}

.center-content {
  color: #f1a10c !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.center-content h1 {
  color:orange!important;

}

@media (max-width: 991px) {
  .header {
    position: relative;
    height: auto !important;
    background-color: #f7f7ff;
    overflow: hidden;
  }
  .header{
    padding-top: 30px;
  }
 
  
  .background-container {
    position: relative;
    width: 100%;
    height: 80vh !important;
    height: 100%;
  }
}

@media (max-width: 576px) {
  .background-container {
    position: relative;
    width: 100%;
    height: 100vh !important;
    height: 100%;
  }
  .header::before{
    display: none;
  }

}

.header h6{
  background-image: url('../../assets/bgtext.png');
  color: #d78c36; 
  background-position: 50%;
  background-repeat: repeat;
  display: inline-block;
  font-size: 21px;
  font-weight: 500;
  margin: 0 0 20px;
  padding: 5px 15px;
}
.header h1{
  font-size: 49px;
  margin-bottom: 50px;
  color: #1f2278;
  font-weight: 700;
}
.header .btn-read{
  text-decoration: none;
  padding: 15px 45px;
  background-color: var(--color-orange);
  color: #fff;
  border-radius: 10px;
  font-size: 19px;
  transition: 0.3s ease-in-out;
}
.header .btn-read:hover{
  background-color: var(--color-orange-hover);
  transition: 0.3s ease-in-out;
}
.img-bg .img01 {
  position: absolute;
  top: 6%;
  left: 20%;
}
.img-bg .img02 {
  position: absolute;
  top: 6%;
  left: 53%;
}
.img-bg .img03 {
  position: absolute;
  top: 74%;
  left: 35%;
}
.img-bg .img04 {
  position: absolute;
  top: 58%;
  left: 1%;
}
@media (min-width: 1515px) {
  .img-bg{
    display: none;
  }

}
@media (max-width: 1300px) {
  .img-bg{
    display: none;
  }
}
@keyframes verticalMove {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px); 
  }
}
.img-vertical-move,
.img02,
.img04 {
  animation: verticalMove 5s ease-in-out infinite;
}


.slick-prev, .slick-next {
  color: white; /* Set the color to white */
}